@import "main/variables";
@import "main/mixins";

// Core CSS
@import "main/typography";
@import "main/misc";

// components
@import "main/navbars";
@import "main/buttons";
@import "main/tooltips-and-popovers";
@import "main/footers";
@import "main/tabs-navs";

// example pages and sections
@import "main/site";
@import "main/sections";

@import "main/responsive";

.btn-round {
	color: #ffffff !important;
	background-image: linear-gradient(310deg, #7928CA, #FF0080);
	border-radius: 40px;
	border: none;
}

.btn-round:hover {
	background-image: linear-gradient(290deg, #7928CA, #FF0080);
	color: #eaeaea !important;
}





/* REACT */
.card {
    position: relative;
    margin: .5rem 0 1rem 0;
    background-color: #fff;
    -webkit-transition: -webkit-box-shadow .25s;
    transition: -webkit-box-shadow .25s;
    transition: box-shadow .25s;
    transition: box-shadow .25s, -webkit-box-shadow .25s;
    border-radius: 2px;
}
.card-image {
    position: relative;
}

.card-image img {
    display: block;
    border-radius: 2px 2px 0 0;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
}

img {
    border-style: none;
}


.card-content {
    padding: 24px;
    border-radius: 0 0 2px 2px;
}
.card-description {
  margin: 0;
}

.card-title {
    display: block;
    line-height: 32px;
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: 300;
}

.btn-floating.btn-large.halfway-fab {
    bottom: -28px;
}

.btn-floating.halfway-fab {
    position: absolute;
    right: 24px;
    bottom: -20px;
}

.btn-floating.btn-large {
    width: 56px;
    height: 56px;
    padding: 0;
}

.btn-large {
    height: 54px;
    line-height: 54px;
    font-size: 15px;
    padding: 0 28px;
}

.btn-floating {
    display: inline-block;
    color: #fff;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    background-color: #26a69a;
    border-radius: 50%;
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    cursor: pointer;
    vertical-align: middle;
}

.btn, .btn-large, .btn-small {
    text-decoration: none;
    color: #fff;
    background-color: #26a69a;
    text-align: center;
    letter-spacing: .5px;
    -webkit-transition: background-color .2s ease-out;
    transition: background-color .2s ease-out;
    cursor: pointer;
}

.btn, .btn-large, .btn-small, .btn-floating, .btn-large, .btn-small, .btn-flat {
    font-size: 14px;
    outline: 0;
}

.z-depth-1, nav, .card-panel, .card, .toast, .btn, .btn-large, .btn-small, .btn-floating, .dropdown-content, .collapsible, .sidenav {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
}

.red {
    background-color: #e7404a !important;
}

.btn-floating.btn-large span {
    line-height: 56px;
}

.btn-large span {
    font-size: 1.6rem;
}

.btn-floating span {
    width: inherit;
    display: inline-block;
    text-align: center;
    color: #fff;
    font-size: 1.6rem;
    line-height: 40px;
}

.hover-hand {
    cursor: pointer;
}